/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0a2449;
  border-radius: 10px;
}

.printDocument img {
  width: 96px;
  height: 96px;
}
.printDocument table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.printDocument table td,
.printDocument table th {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: smaller;
}

.printDocument table tr:nth-child(even) {
  background-color: #eaeaea;
}

.printDocument table tr:hover {
  background-color: #ddd;
}

.printDocument table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
